/**
 * Contains pre-set custom charts, visible in grade 9 Custom Charts tab.
 */

export const setCharts = (currentQuarter) => { return [
    {displayName: ''},
    {
        displayName: 'Chart 1: Current Quarter On-Track',
        rowGroup: 'currentGradeLevel',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Bar Chart'
    },
    {
        displayName: 'Chart 2: Grade 9 On-Track by gender',
        rowGroup: 'gender',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Bar Chart'
    },
    {
        displayName: 'Chart 3: Grade 9 On-Track by Q'+ currentQuarter +' by Team',
        rowGroup: 'schoolTeam',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 4: Grade 9 On-Track by Q'+ currentQuarter +' by OTC',
        rowGroup: 'OTCName',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 5a: Grade 9 On-Track by Race/Ethnicity',
        rowGroup: 'raceAndEthnicity.race',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 5b: Grade 9 On-Track by SPED status',
        rowGroup: 'specialEducation',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 5c: Grade 9 On-Track by ELL status',
        rowGroup: 'englishLearner',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 6: Grade 9 R/O Status',
        rowGroup: 'currentGradeLevel',
        columnGroup: 'g9RiskAndOpportunity.riskAndOpportunity',
        mode: 'Stacked Bar Chart'
    },
    {
        displayName: 'Chart 7: Grade 9 R/O to Q'+ currentQuarter +' On-Track',
        rowGroup: 'g9RiskAndOpportunity.riskAndOpportunity',
        columnGroup: '_currentQuarterOnTrackStatus',
        mode: 'Stacked Bar Chart'
    },
    {
      displayName: 'Chart 8: Grade 9 On-Track by Suspensions',
      rowGroup: 'suspensions',
      columnGroup: '_currentQuarterOnTrackStatus',
      mode: 'Stacked Bar Chart'
    }
]};
